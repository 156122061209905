module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NKS - Nikolas Mosca Full Stack Web Developer","short_name":"NKS","start_url":"/","lang":"en","background_color":"#27292e","theme_color":"#35363b","display":"standalone","icon":"src/assets/images/favicon.png","localize":[{"start_url":"/it/","lang":"it","name":"NKS - Nikolas Mosca Full Stack Web Developer","short_name":"NKS","description":"Sono un Full Stack Web Developer specializzato nello sviluppo Backend. Ho esperienza in analisi e progettazione Back-end e Front-end."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eed84379f02231b5e9f2cd831cd3e848"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://nks-developer.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-R6H372YCTH","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":10,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"backgroundColor":"transparent","tracedSVG":{"color":"#27292e","turnPolicy":"TURNPOLICY_MINORITY","blackOnWhite":false,"turdSize":100,"optCurve":true,"optTolerance":0.4,"threshold":-1,"background":"transparent"},"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
